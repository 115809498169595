import React from 'react';
import {StyledSection} from '../StyledSection';
import "../index.css";

const Experience = () => {
    return (
        <StyledSection>
            <h1>Experience</h1>
            <p>Welcome to the About page</p>
        </StyledSection>
    );
}

export default Experience;